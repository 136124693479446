.center-form {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60%;
    background-color: transparent;
  }

  .center-form .Polaris-Card {
    width: 400px;
    max-width: 500px;
  }
  .center-form .Polaris-Box {
    width: 400px;
  }

  .Polaris-Page {
    height: 100%;
  }

  .Polaris-Page__Content {
    height: 100%;
  }

  .center-form .Polaris-Button--textAlignCenter {
    background-color: #ff4081; /* Custom background color */
    border: 2px solid #ff4081; /* Custom border color */
    color: #ffffff; /* Custom text color */
    justify-content: center;
    text-align: center;
  }

  .center-form .Polaris-Button--textAlignCenter:hover {
    background-color: #ff4081; /* Custom background color */
    border: 2px solid #ff4081; /* Custom border color */
    color: #ffffff; /* Custom text color */
    justify-content: center;
    text-align: center;
    }

    .center-form .Polaris-Button--variantSecondary {
    --pc-button-box-shadow: #ff4081 !important;
  }

  .error-text {
    color: #ff4081; /* Error text color */
    text-align: center;
  }