/* custom.css */
.spinner-container {
    position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; 
  }
  
  /* Custom spinner color */
  .spinner-container .Polaris-Spinner--colorInkLightest {
    border-top-color: #ff4081 !important; /* Custom color */
    border-bottom-color: #ff4081 !important; /* Custom color */
  }

  .Polaris-Spinner svg {
    animation: var(--p-motion-keyframes-spin) var(--p-motion-duration-500) linear infinite;
    fill: #ff4081;
}
  